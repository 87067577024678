<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step ml-3"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <!-- <div class="wizard-wrapper"> -->
              <img :src="detail.photo_url" width="200" alt="" />
              <!-- </div> -->
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h5 class="font-weight-bold text-dark">Detail Alat</h5>

                <div class="card-body">
                  <table class="table table-borderless">
                    <tr>
                      <th class="pr-1" width="130"><span>Nama</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>No. Serial</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.serial_number }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>No. Produk</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.serial_number }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Spesifikasi</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.specification }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Kategori Alat</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.tool_category_name }}</span>
                      </td>
                    </tr>
                    <tr v-if="currentUser.role_id != 2">
                      <th class="pr-1"><span>Harga</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.price }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Status Alat</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.tool_status_name }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>SOP Penggunaan</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.sop_usage }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Merek</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.brand }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Lokasi Penyimpanan</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.location }}</span>
                      </td>
                    </tr>
                    <tr v-if="currentUser.role_id != 2">
                      <th class="pr-1"><span>Tanggal Beli</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.buy_date_display }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Tanggal Tercatat Inventaris</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.inventory_date_display }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="pr-1"><span>Kode Inventory</span></th>
                      <th class="pl-0"><span>:</span></th>
                      <td class="pl-2">
                        <span>{{ detail.inventory_code }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="primary"
                    @click="$router.push('/tools')"
                  >
                    Tutup
                  </b-button>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="success"
                    @click="$router.push('/tools/edit/' + $route.params.id)"
                    v-if="btnAccess"
                  >
                    Edit
                  </b-button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps {
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      currentUser: getUser(),
      detail: {},
      role: {},
      // access
      btnAccess: false,
    };
  },
  methods: {
    getUserById() {
      ApiService.get("api/tools/" + this.$route.params.id)
        .then((response) => {
          //(response);
          this.detail = response.data.data;

          // this.getRoleById(response.data.data.role_id);
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    getRoleById(id) {
      ApiService.get("api/roles/" + id)
        .then((response) => {
          this.role = response.data.data;
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1104") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Alat", route: "/tools" },
      { title: "Detail" },
    ]);

    this.getUserById();
    this.checkAccess();
  },
};
</script>
